import {
  Sidebar as BaseSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from "~/components/ui/sidebar";
import { VersionSwitcher } from "./VersionSwitch";
import Link from "next/link";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useLists } from "~/providers/ListsProvider";
import { HelpCircle, PlusIcon, SettingsIcon, Undo2, Zap } from "lucide-react";
import { ContextMenu } from "~/components/ui/context-menu";
import CreateNestedLists from "./CreateNestedLists";
import { useFolders } from "~/providers/FolderProvider";
import SidebarFolder from "./SidebarFolder";
import SidebarList from "./SidebarList";
import { useDroppable } from "@dnd-kit/core";
import { SubscriptionButton } from "./SubscriptionButton";

export enum SubscriptionStatus {
  TRIAL = "trialing",
  ACTIVE = "active",
}

const footerLinks = [
  {
    isExternal: false,
    text: "Integrations",
    href: "/integrations",
    icon: <Zap />,
  },

  {
    isExternal: false,
    text: "Settings",
    href: "/settings",
    icon: <SettingsIcon />,
  },
  {
    isExternal: true,
    text: "Help",
    href: "https://openq.dev/help",
    icon: <HelpCircle />,
  },
  {
    isExternal: true,
    text: "Back to openq.dev",
    href: "https://openq.dev",
    icon: <Undo2 />,
  },
];

export default function Sidebar() {
  const { activeTeamAccount } = useTeamAccount();
  const { lists } = useLists();

  const { folders } = useFolders();
  const { isOver, setNodeRef } = useDroppable({
    id: "sidebar",
  });
  const className = isOver ? "text-green-500" : "";

  return (
    <>
      <BaseSidebar>
        <SidebarHeader>
          <VersionSwitcher />
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton variant="ghost" asChild>
                  <Link href={"/"}>Overview</Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>

          <SidebarGroup>
            <SidebarGroupLabel className="flex items-center justify-between">
              <Link href={"/lists"}> Lists</Link>
              <Link href={"/lists/new"}>
                <PlusIcon className="ml-auto " />
              </Link>
            </SidebarGroupLabel>
            <SidebarGroupContent className={className} ref={setNodeRef}>
              <ContextMenu>
                <CreateNestedLists>
                  {lists
                    .filter((item) => !item.listFolderId)
                    .map((item) => {
                      return <SidebarList list={item} key={item.id} />;
                    })}
                  {folders.map((item) => {
                    return <SidebarFolder folder={item} key={item.id} />;
                  })}
                </CreateNestedLists>
              </ContextMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                {footerLinks.map((item) => {
                  return (
                    <SidebarMenuItem key={item.text}>
                      <SidebarMenuButton
                        variant="ghost"
                        className="flex items-center"
                      >
                        {item.icon}
                        <Link href={item.href} className="ml-2">
                          {item.text}
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  );
                })}
                <SubscriptionButton
                  status={
                    activeTeamAccount?.stripeSubscription
                      ?.status as SubscriptionStatus
                  }
                />
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarFooter>
        <SidebarRail />
      </BaseSidebar>
    </>
  );
}
